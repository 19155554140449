<template>
	<div>
		<b-row>
			<b-col cols="12">
				<h6 class="mb-0">Add New addon Category</h6>
			</b-col>
			<b-col md="3">
				<b-form-group label="Name GEO" label-for="i-name-geo">
					<b-form-input
						id="i-geo"
						v-model="nameGeo"
						size="sm"
						placeholder="Name here..."
					/>
				</b-form-group>
			</b-col>
			<b-col md="3">
				<b-form-group label="Name ENG" label-for="i-name-eng">
					<b-form-input
						id="i-eng"
						v-model="nameEng"
						size="sm"
						placeholder="Name here..."
					/>
				</b-form-group>
			</b-col>
			<b-col md="2" class="d-flex align-items-center">
				<b-button
					pill
					:disabled="showLoading"
					variant="outline-success"
					size="sm"
					@click="handleAddAddonCategory"
				>
					{{ isEditState ? 'edit' : 'Submit' }}
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import { mapActions, mapState } from 'vuex';

import {
	BRow,
	BCol,
	BFormGroup,
	BFormInput,
	BButton,
	VBToggle,
} from 'bootstrap-vue';

export default {
	components: {
		BFormInput,
		BFormGroup,
		BCol,
		BRow,
		BButton,
	},
	directives: {
		'b-toggle': VBToggle,
	},
	props: {
		categoryToEdit: {
			type: Object,
			default: () => {},
		},
		isEdit: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			nameGeo: '',
			nameEng: '',
		};
	},
	computed: {
		...mapState('addonsModule', ['showLoading', 'addonCategories']),
		isEditState: {
			get() {
				return this.isEdit;
			},
			set(val) {
				this.$emit('update:isEdit', val);
			},
		},
	},
	watch: {
		categoryToEdit(newVal) {
			if (this.isEditState) {
				this.nameGeo = newVal.nameGeo;
				this.nameEng = newVal.nameEng;
			}
		},
	},
	methods: {
		...mapActions('addonsModule', [
			'getAddonCategories',
			'createAddonCategory',
			'editAddonCategory',
		]),
		clearForm() {
			this.nameGeo = '';
			this.nameEng = '';
		},
		handleAddAddonCategory() {
			const payload = {
				nameGeo: this.nameGeo,
				nameEng: this.nameEng,
			};

			if (this.isEditState) {
				payload.id = this.categoryToEdit.id;
				this.editAddonCategory(payload).then((response) => {
					if (response.data.error === null) {
						this.clearForm();
						this.getAddonCategories({
							skip: 0,
							take: 10,
							searchKey: '',
						});
						this.isEditState = false;
					}
				});
			} else {
				this.createAddonCategory(payload).then((response) => {
					if (response.data.error === null) {
						this.clearForm();
						this.getAddonCategories({
							skip: 0,
							take: 10,
							searchKey: '',
						});
					}
				});
			}
		},
	},
};
</script>

<style></style>
