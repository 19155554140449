<template>
	<b-card>
		<CreateAddonForm />
		<b-modal ref="addon-edit-modal" ok-only ok-title="Close" size="xl" centered>
			<EditAddonForm :addon-to-edit="addonToEdit" :close-modal="closeModal" />
		</b-modal>
		<AddonTable
			:addons-list="addonsList"
			:edit-addon-button="editAddonButton"
			:remove-addon-button="removeAddonButton"
			:search-query="searchQuery"
			:show-loading="showLoading"
		/>
		<CreateAddonCategoryFrom
			:category-to-edit="categoryToEdit"
			:is-edit.sync="isEdit"
		/>
		<CatsTable
			:cats-list="addonCategories"
			:edit-cat-button="editCatButton"
			:remove-cat-button="removeCatButton"
			:cat-search-query="catSearchQuery"
		/>
	</b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import { mapActions, mapState } from 'vuex';
import Swal from 'sweetalert2';
import AddonTable from './Components/AddonTable.vue';
import CatsTable from './Components/CatsTable.vue';
import CreateAddonForm from './Components/CreateAddonForm.vue';
import EditAddonForm from './Components/EditAddonForm.vue';
import CreateAddonCategoryFrom from './Components/CreateAddonCategoryForm.vue';

export default {
	components: {
		BCard,
		AddonTable,
		CatsTable,
		CreateAddonForm,
		EditAddonForm,
		CreateAddonCategoryFrom,
	},
	data() {
		return {
			isEdit: false,
			categoryToEdit: null,
			addonsList: [],
			catsList: [],
			searchQuery: '',
			catSearchQuery: '',
			addonToEdit: null,
		};
	},

	computed: {
		...mapState('addonsModule', [
			'addons',
			'addonCategories',
			'singleAddon',
			'showLoading',
		]),
	},
	watch: {
		searchQuery(newVal) {
			this.getAddons({
				skip: 0,
				take: 10,
				searchKey: newVal,
			});
		},
		catSearchQuery(newVal) {
			this.getAddons({
				skip: 0,
				take: 10,
				searchKey: newVal,
			});
		},
		addons(newVal) {
			this.addonsList = newVal;
			console.log('addons', newVal);
		},
		addonCategories(newVal) {
			this.catsList = newVal;
			console.log('addonCategories', newVal);
		},
	},
	created() {
		this.fetchAddons();
		this.getAddonCategories({
			skip: 0,
			take: 10,
			searchKey: '',
		});
	},
	methods: {
		...mapActions('addonsModule', [
			'getAddons',
			'getAddonById',
			'updateAddonById',
			'deleteAddonById',
			'getAddonCategories',
			'deleteAddonCategory',
		]),
		closeModal() {
			this.$refs['addon-edit-modal'].hide();
			this.addonToEdit = null;
		},
		editAddonButton(prod) {
			console.log(prod);
			this.addonToEdit = prod;
			this.$refs['addon-edit-modal'].show();
		},
		removeAddonButton(prod) {
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#28a745',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete!',
			}).then((result) => {
				if (result.isConfirmed) {
					this.deleteAddonById(prod.id).then(() => {
						this.fetchAddons();
					});
				}
			});
		},
		fetchAddons() {
			this.getAddons({
				skip: 0,
				take: 10,
				searchKey: '',
			});
		},
		editCatButton(cat) {
			this.isEdit = true;
			this.categoryToEdit = cat;
		},
		removeCatButton(prod) {
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#28a745',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete!',
			}).then((result) => {
				if (result.isConfirmed) {
					this.deleteAddonCategory(prod.id).then(() => {
						this.getAddonCategories();
					});
				}
			});
		},
	},
};
</script>

<style>
.filters .form-group {
	max-width: 300px;
}
</style>
