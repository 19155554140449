<template>
	<div>
		<b-button
			pill
			size="sm"
			v-b-toggle.create-addon-collapse
			variant="outline-primary"
			class="mb-1 ml-auto d-block"
		>
			Add new addon
		</b-button>
		<b-collapse
			id="create-addon-collapse"
			class="border mb-1 rounded shadow-sm"
		>
			<b-overlay :show="showLoading" rounded="sm" class="p-1">
				<b-row>
					<b-col cols="12" class="mb-2">
						<h5 class="mb-0">Basic Information</h5>
					</b-col>
					<b-col md="3">
						<b-form-group label="Name GEO" label-for="i-name-geo">
							<b-form-input
								id="i-geo"
								v-model="nameGeo"
								placeholder="Name here..."
							/>
						</b-form-group>
					</b-col>
					<b-col md="3">
						<b-form-group label="Name ENG" label-for="i-name-eng">
							<b-form-input
								id="i-eng"
								v-model="nameEng"
								placeholder="Name here..."
							/>
						</b-form-group>
					</b-col>
					<b-col md="3">
						<b-form-group label="Count" label-for="i-name-eng">
							<b-form-input
								id="i-eng"
								v-model="count"
								placeholder="Name here..."
								type="number"
							/>
						</b-form-group>
					</b-col>
					<b-col md="3">
						<b-form-group label="Price" label-for="i-name-eng">
							<b-form-input
								id="i-eng"
								v-model="price"
								placeholder="Name here..."
								type="number"
							/>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col md="3">
						<b-form-group label="Addon type" label-for="i-name-eng">
							<b-form-select
								v-model="type"
								:options="typeOptions"
								text-field="label"
								value-field="id"
							/>
						</b-form-group>
					</b-col>
					<b-col md="3">
						<b-form-group label="Addon Category" label-for="i-name-eng">
							<b-form-select
								v-model="addOnCategoryId"
								:options="addonCategories"
								text-field="nameGeo"
								value-field="id"
								placeholder="select"
							>
								<b-form-select-option :value="null">
									Select category
								</b-form-select-option>
							</b-form-select>
						</b-form-group>
					</b-col>
					<b-col md="3">
						<b-form-group label="Select Main Image" label-for="i-name-eng">
							<b-form-file
								v-model="image"
								placeholder="Choose a file or drop it here..."
								drop-placeholder="Drop file here..."
							/>
						</b-form-group>
					</b-col>
					<b-col md="1" class="d-flex align-items-center">
						<b-form-checkbox
							v-model="isFree"
							checked="true"
							name="check-button"
							inline
						>
							Free
						</b-form-checkbox>
					</b-col>
					<b-col md="2" class="d-flex align-items-center">
						<b-button
							pill
							:disabled="showLoading"
							variant="outline-success"
							@click="handleAddAddon"
						>
							Submit
						</b-button>
					</b-col>
				</b-row>
			</b-overlay>
		</b-collapse>
	</div>
</template>
<script>
import { mapActions, mapState } from 'vuex';

import {
	BRow,
	BCol,
	BFormGroup,
	BFormInput,
	BFormSelect,
	BFormSelectOption,
	BFormFile,
	BFormCheckbox,
	BCollapse,
	BButton,
	VBToggle,
	BOverlay,
} from 'bootstrap-vue';

export default {
	components: {
		BFormInput,
		BFormGroup,
		BCol,
		BFormSelect,
		BFormSelectOption,
		BFormFile,
		BFormCheckbox,
		BRow,
		BCollapse,
		BButton,
		BOverlay,
	},
	directives: {
		'b-toggle': VBToggle,
	},
	data() {
		return {
			addOnCategoryId: null,
			type: null,
			nameGeo: '',
			nameEng: '',
			count: 0,
			price: 0,
			isFree: false,
			image: null,
			typeOptions: [
				{
					label: 'Select type',
					id: null,
				},
				{
					label: 'Background Letter',
					id: 1,
				},
				{
					label: 'DigitalWrapping',
					id: 2,
				},
			],
		};
	},
	computed: {
		...mapState('addonsModule', ['showLoading', 'addonCategories']),
	},
	created() {
		this.getAddonCategories({
			skip: 0,
			take: 10,
			searchKey: '',
		});
	},
	methods: {
		...mapActions('addonsModule', [
			'getAddonCategories',
			'createAddon',
			'getAddons',
		]),
		handleImageUploadChange(e) {
			console.log(e);
		},
		clearForm() {
			this.addOnCategoryId = null;
			this.type = null;
			this.nameGeo = '';
			this.nameEng = '';
			this.count = 0;
			this.price = 0;
			this.isFree = false;
			this.image = null;
		},
		handleAddAddon() {
			const formDataObject = {
				addOnCategoryId: this.addOnCategoryId,
				type: this.type,
				nameGeo: this.nameGeo,
				nameEng: this.nameEng,
				count: this.count,
				price: this.price,
				isFree: this.isFree,
				image: this.image,
			};
			const formData = new FormData();

			Object.keys(formDataObject).forEach((e) => {
				formData.append(e, formDataObject[e]);
			});

			this.createAddon(formData).then((response) => {
				if (response.data.error === null) {
					this.clearForm();
					this.getAddons({ skip: 0, take: 10, searchKey: '' });
				}
			});
		},
	},
};
</script>

<style></style>
