<template>
	<div>
		<b-overlay :show="showLoading" rounded="sm" class="p-1">
			<b-row>
				<b-col cols="12" class="mb-2">
					<h5 class="mb-0">Basic Information</h5>
				</b-col>
				<b-col md="3">
					<b-form-group label="Name GEO" label-for="i-name-geo">
						<b-form-input
							id="i-geo"
							v-model="nameGeo"
							placeholder="Name here..."
						/>
					</b-form-group>
				</b-col>
				<b-col md="3">
					<b-form-group label="Name ENG" label-for="i-name-eng">
						<b-form-input
							id="i-eng"
							v-model="nameEng"
							placeholder="Name here..."
						/>
					</b-form-group>
				</b-col>
				<b-col md="3">
					<b-form-group label="Count" label-for="i-name-eng">
						<b-form-input
							id="i-eng"
							v-model="count"
							placeholder="Name here..."
							type="number"
						/>
					</b-form-group>
				</b-col>
				<b-col md="3">
					<b-form-group label="Price" label-for="i-name-eng">
						<b-form-input
							id="i-eng"
							v-model="price"
							placeholder="Name here..."
							type="number"
						/>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col md="3">
					<b-form-group label="Addon type" label-for="i-name-eng">
						<b-form-select
							v-model="type"
							:options="typeOptions"
							text-field="label"
							value-field="id"
						/>
					</b-form-group>
				</b-col>
				<b-col md="3">
					<b-form-group label="Addon Category" label-for="i-name-eng">
						<b-form-select
							v-model="addOnCategoryId"
							:options="addonCategories"
							text-field="nameGeo"
							value-field="id"
							placeholder="select"
						>
							<b-form-select-option :value="null">
								Select category
							</b-form-select-option>
						</b-form-select>
					</b-form-group>
				</b-col>
				<b-col md="3">
					<b-overlay :show="showImageOverlay" rounded="sm">
						<template #overlay>
							<div class="d-flex">
								<b-button
									ref="cancel"
									variant="primary"
									size="sm"
									aria-describedby="cancel-label"
									@click="showImageOverlay = false"
								>
									Edit Image
								</b-button>
							</div>
						</template>
						<b-form-group label="Select Main Image" label-for="i-name-eng">
							<b-form-file
								v-model="image"
								placeholder="Choose a file or drop it here..."
								drop-placeholder="Drop file here..."
							/>
						</b-form-group>
					</b-overlay>
				</b-col>
				<b-col md="1" class="d-flex align-items-center">
					<b-form-checkbox
						v-model="isFree"
						checked="true"
						name="check-button"
						inline
					>
						Free
					</b-form-checkbox>
				</b-col>
				<b-col md="2" class="d-flex align-items-center">
					<b-button
						pill
						:disabled="showLoading"
						variant="outline-success"
						@click="handleAddAddon"
					>
						Edit
					</b-button>
				</b-col>
			</b-row>
		</b-overlay>
	</div>
</template>
<script>
/* eslint-disable */
import { mapActions, mapState } from 'vuex';

import {
	BRow,
	BCol,
	BFormGroup,
	BFormInput,
	BFormSelect,
	BFormSelectOption,
	BFormFile,
	BFormCheckbox,
	BButton,
	BOverlay,
} from 'bootstrap-vue';

export default {
	components: {
		BFormInput,
		BFormGroup,
		BCol,
		BFormSelect,
		BFormSelectOption,
		BFormFile,
		BFormCheckbox,
		BRow,
		BButton,
		BOverlay,
	},
	props: {
		addonToEdit: {
			type: Object,
			default: () => {},
		},
		closeModal: {
			type: Function,
			default: () => {},
		},
	},
	data() {
		return {
			showImageOverlay: true,
			addOnCategoryId: null,
			type: null,
			nameGeo: '',
			nameEng: '',
			count: 0,
			price: 0,
			isFree: false,
			image: null,
			typeOptions: [
				{
					label: 'Select type',
					id: null,
				},
				{
					label: 'Background Letter',
					id: 1,
				},
				{
					label: 'DigitalWrapping',
					id: 2,
				},
			],
		};
	},
	computed: {
		...mapState('addonsModule', ['showLoading', 'addonCategories']),
	},
	created() {
		this.getAddonCategories({
			skip: 0,
			take: 10,
			searchKey: '',
		});
	},
	mounted() {
		this.setAddonDetails();
	},
	methods: {
		...mapActions('addonsModule', [
			'getAddonCategories',
			'getAddons',
			'updateAddonById',
		]),
		setAddonDetails() {
			this.addOnCategoryId = this.addonToEdit.category.id;
			this.type = this.addonToEdit.type;
			this.nameGeo = this.addonToEdit.nameGeo;
			this.price = this.addonToEdit.price;
			this.nameEng = this.addonToEdit.nameEng;
			this.count = this.addonToEdit.count;
			this.isFree = this.addonToEdit.isFree;
			this.image = null;
		},
		clearForm() {
			this.addOnCategoryId = null;
			this.type = null;
			this.nameGeo = '';
			this.nameEng = '';
			this.count = 0;
			this.price = 0;
			this.isFree = false;
			this.image = null;
		},

		objectToFormData(obj, form, namespace) {
			const fd = form || new FormData();
			let formKey;

			for (const property in obj) {
				if (obj.hasOwnProperty(property)) {
					if (namespace) {
						if (obj[property] instanceof File) {
							formKey = namespace + '.' + property;
						} else {
							formKey = namespace + '[' + property + ']';
						}
					} else {
						formKey = property;
					}

					// if the property is an object, but not a File,
					// use recursivity.
					if (
						typeof obj[property] === 'object' &&
						!(obj[property] instanceof File)
					) {
						this.objectToFormData(obj[property], fd, formKey);
					} else {
						// if it's a string or a File object
						fd.append(formKey, obj[property]);
					}
				}
			}

			return fd;
		},
		handleAddAddon() {
			const formDataObject = {
				addonId: this.addonToEdit.id,
				addOnCategoryId: this.addOnCategoryId,
				type: this.type,
				nameGeo: this.nameGeo,
				nameEng: this.nameEng,
				count: this.count,
				price: this.price,
				isFree: this.isFree,
				imageUpdate: {
					isPreviousDeleted: !!this.image,
					image: this.image,
				},
			};

			const payload = this.objectToFormData(formDataObject);

			this.updateAddonById(payload).then((response) => {
				if (response.data.error === null) {
					this.clearForm();
					this.closeModal();
					this.getAddons({ skip: 0, take: 10, searchKey: '' });
				}
			});
		},
	},
};
</script>

<style></style>
