<template>
	<div>
		<h5>Addon categories</h5>
		<div v-if="false" class="filters">
			<b-form-group label="Search in categories" class="form-group">
				<b-form-input v-model="searchQuery" placeholder="search term here" />
			</b-form-group>
		</div>
		<b-table
			ref="catsListTable"
			bordered
			hover
			responsive
			class="shadow-sm rounded"
			thead-tr-class="order-main-thead"
			:items="catsList"
			:fields="fields"
			primary-key="id"
			show-empty
			empty-text="No matching records found"
			small
		>
			<template #cell(actions)="data">
				<b-button
					variant="flat-primary"
					class="btn-icon"
					@click="editCatButton(data.item)"
				>
					<feather-icon size="16" icon="EditIcon" />
				</b-button>
				<b-button
					class="btn-icon"
					variant="flat-danger"
					@click="removeCatButton(data.item)"
				>
					<feather-icon size="16" icon="TrashIcon" />
				</b-button>
			</template>
		</b-table>
		<div class="mx-2 mb-2">
			<b-row v-if="false">
				<!-- <b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span v-if="true" class="text-muted">
							Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
						</span>
					</b-col>
					Pagination -->
				<b-col
					cols="12"
					sm="6"
					class="d-flex align-items-center justify-content-center justify-content-sm-end pr-sm-4"
				>
					<b-pagination
						v-model="currentPage"
						:total-rows="totalProducts"
						:per-page="perPage"
						first-number
						last-number
						class="mt-1 mb-0 mt-sm-0"
						prev-class="prev-item"
						next-class="next-item"
					>
						<template #prev-text>
							<feather-icon icon="ChevronLeftIcon" size="18" />
						</template>
						<template #next-text>
							<feather-icon icon="ChevronRightIcon" size="18" />
						</template>
					</b-pagination>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
import {
	BTable,
	BFormInput,
	BFormGroup,
	BButton,
	BCol,
	BPagination,
	BRow,
} from 'bootstrap-vue';

export default {
	components: {
		BTable,
		BButton,
		BFormInput,
		BFormGroup,
		BCol,
		BPagination,
		BRow,
	},
	props: {
		catsList: {
			type: Array,
			default: () => [],
		},
		editCatButton: {
			type: Function,
			default: () => {},
		},
		removeCatButton: {
			type: Function,
			default: () => {},
		},
		searchQuery: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			fields: [
				{
					label: 'id',
					key: 'id',
				},
				{
					label: 'Category name',
					key: 'nameGeo',
					sortable: true,
					formatter: (value, key, item) => `${item.nameGeo} / ${item.nameEng}`,
				},
				{
					label: 'Actions',
					key: 'Actions',
				},
			],
		};
	},
	watch: {
		//
	},
	created() {
		//
	},
};
</script>

<style>
.filters .form-group {
	max-width: 300px;
}
</style>
